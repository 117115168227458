<template>
  <div class="row">
    <div class="col-12 mb-4">
      <div class="card border-light shadow-sm components-section">
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <div class="mb-5">
                <div class="overlay overlay-show">
                  <div
                    class="
                      w-100
                      d-flex
                      justify-content-center
                      align-items-center
                    "
                  >
                    <div class="spinner"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TestingComponent',
  computed: {
    ...mapGetters({
      menuRole: 'menu/menuRole',
    }),
  },
  watch: {
    menuRole: function (newVal, oldVal) {
      if (newVal.length) {
        let route = newVal[0].route
        this.$router.push({
          name: route,
        })
      }
    },
  },
}
</script>
